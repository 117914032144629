import React, { useState, useEffect } from 'react';
import Header from '../Header'; 
import { useNavigate  } from 'react-router-dom';

function BestMemeCoinReview() {
    const [articles, setArticles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const navigate = useNavigate();

    const privacyLink = () => {
        navigate('/privacy/');
    };
  
    const termsLink = () => {
        navigate('/terms/');
    };

    useEffect(() => {
      const fetchArticles = async () => {
          try {
              const response = await fetch('https://eventregistry.org/api/v1/article/getArticles?apiKey=49fc0350-77a7-4bcd-a534-8d1a376414d1&keyword=technology&lang=eng&articlesCount=9&keywordLoc=title');
              if (!response.ok) {
                  throw new Error('Network response was not ok');
              }
              const data = await response.json();
              setArticles(data.articles.results); 
          } catch (error) {
              setError(error);
          } finally {
              setLoading(false);
          }
      };

      fetchArticles();
  }, []);

    if (error) return <p>Error: {error.message}</p>;

    return (
<div className="App">
<div className="App">
  <Header />
  {/* Compact header with a full-width image and a centered headline */}
  <header
    className="relative w-full h-80 bg-cover bg-center"
    style={{
      backgroundImage: "url(/tech-bg.jpg)", // Use a different image for the new layout
    }}
  >
    <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="text-center">
        <h1 className="text-5xl text-white font-extrabold tracking-wider">
          Today's Headlines
        </h1>
        <p className="text-lg text-gray-300 mt-3">
          Stay updated with the latest news and trends
        </p>
      </div>
    </div>
  </header>

  {/* Main section with articles arranged in a responsive grid */}
  <main className="container mx-auto py-16 px-6">
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
      {articles.map((article) => (
        <div
          className="bg-gray-900 p-6 rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-300"
          key={article.uri}
        >
          <img
            src={article.image || '/tech-bg.jpg'}
            alt={article.title}
            className="rounded-t-lg h-48 w-full object-cover mb-4"
          />
          <h2 className="text-2xl font-semibold text-white mb-3">
            {article.title}
          </h2>
          <a
            className="bg-teal-500 text-white px-4 py-2 rounded-lg inline-block hover:bg-teal-600 transition-colors duration-300"
            href={article.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            Read more
          </a>
        </div>
      ))}
    </div>
  </main>

  {/* Minimalist footer with links */}
  <footer className="bg-gray-800 text-gray-400 py-6">
    <div className="container mx-auto text-center">
      <p className="text-sm leading-relaxed">
        Disclaimer: The information provided here is for informational purposes
        only. Please verify facts before making decisions.
      </p>
      <div className="mt-4">
        <span
          onClick={privacyLink}
          style={{ cursor: "pointer", color: "white" }}
          className="mr-4"
        >
          Privacy Policy
        </span>
        <span
          onClick={termsLink}
          style={{ cursor: "pointer", color: "white" }}
        >
          Terms of Use
        </span>
      </div>
      <p className="mt-6 text-teal-500">© 2024 EvoFuturo. All rights reserved.</p>
    </div>
  </footer>
</div>
</div>

    );
}

export default BestMemeCoinReview;
