import React, { useState, useEffect } from 'react';
import { useNavigate  } from 'react-router-dom';

function Header () {
    const navigate = useNavigate();

    const handleNavigation = () => {
        navigate('/');
    };

    return (
        <div
  style={{
    position: "relative",
    width: "100%",
    height: 70,
    color: "white",
    overflow: "hidden"
  }}
>
  <div
    style={{
      position: "absolute",
      width: "100%",
      height: 70,
      backgroundColor: "black"
    }}
  />
  <div
    style={{
      position: "absolute",
      width: "100%",
      top: 70,
      height: 69,
      borderBottom: "1px solid rgb(240, 240, 240)"
    }}
  />
  <div
    style={{
      position: "relative",
      width: 1200,
      marginLeft: "calc(50% - 600px)",
      height: "100%",
      display: "flex"
    }}
    className='header3949001Item'
  >
    <h2
                            style={{
                                display: "flex",
                                alignItems: "center",
                                color: "white",
                                fontWeight: 800,
                                fontSize: 20,
                                gap: 10,
                                width: "30%",
                                cursor: "pointer"
                            }} 
                            onClick={handleNavigation}
                        >
                            <img
                                src="/cube.png"
                                alt=""
                                width={50}
                            />
                            EvoFuturo
                        </h2>
    <div
      style={{
        position: "relative",
        width: "calc(100% - 200px)",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "end"
      }} className='header981390139050Item'
    >
      <div
        className="clickable_text"
        style={{
          textDecoration: "underline",
          display: "inline-block",
          lineHeight: 50,
          paddingLeft: 10,
          paddingRight: 10,
          cursor: "pointer"
        }}
        onClick={handleNavigation}
      >
        News
      </div>
    </div>
  </div>
</div>

    )
}

export default Header;